<template>
    <div class="mainform">
        <!-- 创建 付款申请下一步 -->
      <div class="mainHeader">创建付款申请</div>
        <div class="form">
            <div class="header">付款内容</div>
                <el-form  class="content bascform disabled" :model="form" :disabled="true" style="padding-bottom: 0;" label-width="100px">
                  <div  class="col col4">
                        <el-form-item label="公司名称" prop="companyName">
                            <el-input v-model="form.companyName"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="开票方" prop="drawerName">
                            <el-input v-model="form.drawerName"></el-input>
                        </el-form-item>
                    </div>
                    <div  class="col col4">
                        <el-form-item label="币种" prop="standardCoin">
                            <el-input v-model="form.standardCoin"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col col4">
                        <el-form-item label="申请总额" prop="totalMoney">
                          <el-input v-model="totalMoney"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <el-form  class="content bascform" :model="form" style="padding-top: 0;" label-width="100px">
                   <div class="col col4">
                        <el-form-item label="备注" prop="bzdata">
                            <el-input v-model="bzdata"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
            <div class="header">付款明细</div>
            <global-table class="bascform" max-height="350" :tableField="tableField" :tableData="tableData" ></global-table>
        </div>
        <div class="action" >
            <el-button class="submit" type="primary" size="medium" @click="goBack">上一步</el-button>
            <el-button class="submit" type="primary" size="medium" @click="submit">提交</el-button>
        </div>
    </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField } from './js/createPaymentreqNext'
import { request } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'CreatePaymentreqNext',
  data () {
    FlowCtlData.docid = ''
    if (!this.$store.state.NextData.payapplay) {
      this.$router.push('/FinancialManagement/CreatePaymentreq')
      return {
        tableField: tableField,
        tableData: [],
        form: {},
        bzdata: '',
        totalMoney: 0
      }
    }
    return {
      tableField: tableField,
      tableData: this.$store.state.NextData.detaildata,
      form: this.$store.state.NextData.payapplay[0],
      bzdata: '',
      totalMoney: 0,
      sfsp: 'f',
      buyerLeader: '',
      accountant: ''
    }
  },
  created () {
    if (this.$store.state.NextData.payapplay) {
      for (const item of this.$store.state.NextData.payapplay) {
        this.totalMoney = (parseFloat(this.totalMoney) + parseFloat(item.applicationAmount)).toFixed(2)
        if (new Date(item.maturityDate) > new Date(sessionStorage.getItem('serversDate'))) {
          this.sfsp = 'y'
        }
      }
      FlowCtlData.flowid = 'PaymentApproval'
      FlowCtlData.initialize()
      FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.buyerLeader = val })
      FlowCtlData.getApprPsn('561052621739134976').then((val) => { this.accountant = val })
    }
  },
  methods: {
    submit () {
      var lcobj = {}
      var obj = {
        accountsPayableList: this.$store.state.NextData.payapplay,
        accountsPayableDetailList: this.tableData,
        bz: this.bzdata,
        totalMoney: this.totalMoney
      }
      obj.buyerLeader = this.buyerLeader
      obj.accountant = this.accountant
      obj.sfsp = this.sfsp
      obj.approvalComplete = this.form.approvalComplete

      lcobj.buyerLeader = this.buyerLeader
      lcobj.accountant = this.accountant
      lcobj.sfsp = this.sfsp
      lcobj.approvalComplete = this.form.approvalComplete

      const tempspr = obj.accountant
      FlowCtlData.getApprPsn('', `${obj.accountsPayableList[0].companyCode}-公司【付款审批】`).then((val) => {
        obj.accountant = val
        lcobj.accountant = val
        if (obj.accountant === '') {
          obj.accountant = tempspr
          lcobj.accountant = tempspr
        }
        FlowCtlData.getNodeAction('TJ', lcobj).then((val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              FlowCtlData.setFormData(FlowCtlData, lcobj)
              obj.status = 1
              request('/api/reconciliation/pay/saveDetail', 'POST', obj).then((Response) => {
                if (Response.code === '200') {
                  FlowCtlData.docid = Response.data
                  FlowCtlData.setNodeAction().then((val) => {
                    FlowCtlData.formAlert(this, 'success')
                    this.$router.push('/ContractOrder/PaymentFind')
                  })
                }
              })
            }).catch(() => { FlowCtlData.formAlert(this, 'info') })
          }
        }).catch(() => { FlowCtlData.formAlert(this, 'error') })
      })
    },
    confirmAdd () {
      this.dialogShow = false
    },
    handleClose () {
      this.dialogShow = false
    },
    addDetail () {
      this.dialogShow = true
    },
    goBack () {
      this.$router.push('/FinancialManagement/CreatePaymentreq')
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';
  .mainform{
    :deep(.tableList){
      padding: 0px;
    }
  }
</style>
